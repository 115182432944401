/* eslint-disable */

window.simpleParallax = require('simple-parallax-js')
window.AOS = require('aos')
require('lightgallery.js')

lightGallery(document.getElementById('lightgallery'), {
  download: false
})

const parallax = new simpleParallax(
  document.getElementsByClassName('parallax'),
  {
    overflow: true,
    scale: 1.5
  }
)

parallax.handleResize()

AOS.init({
  duration: 800
});

function scrollTo(element) {
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: element.offsetTop
  });
}

document.getElementById("top-button").addEventListener('click', (e) => {
  e.preventDefault();
  scrollTo(document.getElementById("content-container"));
});

/* eslint-enable */
